/**
 * formats a string so that it can be used as a url param
 * @param  {[string]} string               [string to be formatted]
 * @return {[string]}             [formatted string]
 */

export const formatStringToUrlParam = (string: string): string => {
  const noSpacesSting = string.toLowerCase().replace(/ /g, '-')
  const noSpecialCharsString = noSpacesSting.normalize('NFD').replace(/[\u0300-\u036F]/g, '')
  return noSpecialCharsString
}
